(function ($) {

    var _this = this;

    function overload(context, name, func) {
        var sup = context[name];
        context[name] = function () {
            if (typeof sup == 'function') sup.apply(context, arguments);
            func.apply(context, arguments);
        };
    }

    function bindConsole(type, cb) {
        return overload(console, type, cb);
    }

    function bindError(cb) {
        return overload(window, 'onerror', function (messageOrEvent, source, lineno, colno, error) {
            cb(error);
        });
    }

    function sendError(msg) {
        var report = {
            type: 'CLIENT',
            level: 'ERROR',
            trace: msg,
            url: window.location && window.location.href,
            userAgent: window.navigator && window.navigator.userAgent,
        };

        if (window.userConnected)
            Object.assign(report, { userId: userConnected.id, userEmail: userConnected.email, optionalData: userConnected });

        $.post('/api/report', report);
    }

    function tracekitReportToString(report) {
        if (!report) return;
        var msg = ["Error: " + JSON.stringify(report.message)];

        if (report.stack) {
            for (var i = 0; i < report.stack.length; ++i) {
                var stack = report.stack[i];
                if (stack) {
                    var line = stack.url + ":" + stack.line + ":" + stack.column;
                    msg.push("    at " + (stack.func != '?' ? (stack.func + " (" + line + ")") : line));
                    if (stack.args && stack.args.length)
                        msg.push("        called with: (" + JSON.stringify(stack.args).substr(0, 80) + ")");
                    if (typeof (stack.context) === "string")
                        msg.push("        on context: " + stack.context.substr(0, 80));
                }
                else
                    msg.push("    at unknown");
            }
        }
        return msg.join('\n');
    }

    function listen(options) {
        var ignoreList = options.ignore || [];
        var sendIfNotIgnored = function (msg) {
            if (typeof msg != 'string' || msg === '') return;
            for (var i = 0; i < ignoreList.length; i++) {
                if (msg.indexOf(ignoreList[i]) != -1) return;
            }
            sendError(msg);
        };

        if (typeof TraceKit != 'undefined') {
            TraceKit.report.subscribe(function logger(report) {
                var msg = tracekitReportToString(report);
                sendIfNotIgnored(msg);
            });
        }
        else
            bindError(sendIfNotIgnored);

        bindConsole('error', sendIfNotIgnored);
    }

    listen({
        ignore: [
            // Vendor error without stacktrace
            'Script error.',
            'Transport:',
            // https://github.com/getsentry/sentry/issues/5267
            'Blocked a frame with origin',
            // angular-ui-router redirection
            'The transition has been superseded by a different transition',
            'NotAllowedError',
            'OverconstrainedError',
            'VidyoWebRTC: getUserMedia',
            'VidyoDevice: Start device',
            'Error: [$rootScope:inprog]',
            'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission'
        ]
    });

})(jQuery);
